body,
#root {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto !important;
  float: none !important;
}

.app {
  text-align: center;
}

.app-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px);
}

.layout-content {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  margin: 1.25em;
}

.ant-layout-sider-children {
  background-color: #08979c;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  border-radius: 4px;
}

.ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-size: 16px;
}

.ant-form-item-label {
  font-weight: bold;
}

.ant-card {
  border-radius: 4px;
  margin-bottom: 20px;
}

.ant-card-head {
  border-radius: 4px 4px 0 0;
}

.ant-card-body {
  border-radius: 4px;
}

.ant-modal-content {
  border-radius: 4px;
}

.ant-modal-header {
  padding: 0;
  border-radius: 4px 4px 0 0;
}

.ant-input {
  height: 40px;
  border-radius: 4px;
}

.ant-input-number {
  height: 40px;
  width: 100%;
  border-radius: 4px;
}

.ant-input-password {
  height: 40px;
  border-radius: 4px;
}

.ant-input-password .ant-input {
  height: 28px;
}

.ant-input-search {
  height: 40px;
  border-radius: 4px;
}

.ant-input-search .ant-input {
  height: 32px;
}

.ant-picker {
  height: 40px;
  border-radius: 4px;
}

textarea.ant-input {
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  border-radius: 4px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 35px;
}

.selectInput {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  border-radius: 4px;
  transition: all 0.3s;
}