.sidebar .ant-menu-item-active,
.sidebar .ant-menu-submenu-active {
    color: #D2D2D2 !important;
}

.sidebar .ant-menu-item-selected {
    color: #003a8c !important;
}

.sidebar .ant-menu-submenu-open .ant-menu-item-selected {
    color: #D2D2D2;
}

.sidebar .ant-menu-submenu-open .ant-menu-item-only-child {
    color: #D2D2D2;
}

.sidebar .ant-menu-submenu-open .ant-menu-item-selected .ant-menu-item-active {
    color: #003a8c;
}

.sidebar .ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #003a8c;
}

.sidebar .ant-menu-submenu-selected {
    color: #003a8c;
}