.not-found-page {
  position: fixed;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.not-found-page .ant-image.logo {
  display: block;
}
.not-found-page .ant-image.logo .ant-image-img {
  display: block;
  width: 250px;
  height: auto;
  margin: auto;
}